import React from "react";
import FormComponent from "../components/form/form";
import Layout from "../components/layout/layout";

const Partner = () => {
  return (
    <Layout design="dark" logo="green">
      <FormComponent />
    </Layout>
  );
};

export default Partner;
